
const HomeTrilook = (props) => {

  return (
    <>
     <section className="home_trialbook">
                <div className="" dangerouslySetInnerHTML={{ __html:props.content }} />
            </section>
    </>
   
  );
};

export default HomeTrilook;
